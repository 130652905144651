import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { ParagraphSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

interface TicketsInfoIconTooltipProps {
  isDark?: boolean
  iconSize?: number
  shouldUseNewAfterDeathGoal?: boolean
  overlayClassname?: string
}

export const TicketsInfoIconTooltip: React.FC<TicketsInfoIconTooltipProps> = ({
  isDark = true,
  iconSize = 16,
  shouldUseNewAfterDeathGoal = false,
  overlayClassname,
}) => {
  const [isShown, setShown] = useState<boolean>(false)
  const { t } = useTranslate('tickets')

  const toggleShown = () => setShown((currentShown) => !currentShown)
  const infoText = useMemo(() => {
    if (shouldUseNewAfterDeathGoal) {
      return t(
        'shouldUseNewAfterDeathGoal',
        "This goal represents the number of people who will pass today. Death isn't the end--there is hope, there is light. After Death explores one of life's biggest questions: What happens after we die?",
      )
    }
    return t(
      'totalTicketsPIFDisclaimer',
      'Total tickets are an estimate based on current box office sales. Pay it Forward is only included in the total when a free ticket is redeemed to watch in theaters.',
    )
  }, [shouldUseNewAfterDeathGoal, t])

  return (
    <div
      onMouseOver={() => setShown(true)}
      onMouseOut={() => setShown(false)}
      onClick={toggleShown}
      className="relative z-10 inline py-[5px]"
    >
      <ParagraphSM
        className={classNames(
          'absolute top-full right-0 rounded text-sm w-[260px] py-2 px-2 text-center !normal-case drop-shadow-md transition ease-in-out',
          isDark ? 'text-gray-200 bg-core-gray-900' : 'text-gray-950 bg-white',
          {
            'scale-100': isShown,
            'scale-0': !isShown,
          },
          overlayClassname,
        )}
        weight="normal"
      >
        {infoText}
      </ParagraphSM>
      <CircleInfoIcon size={iconSize} color={isDark ? 'gray-100' : 'gray-500'} />
    </div>
  )
}
