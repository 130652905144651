import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CircleInfoIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'white', className }) => {
  const c = getHexColor(color)
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill={c}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.01579 11.7129C8.20468 11.7129 8.36312 11.6489 8.49112 11.5209C8.61868 11.3933 8.68245 11.2351 8.68245 11.0462V8.36289C8.68245 8.174 8.61868 8.01845 8.49112 7.89622C8.36312 7.774 8.20468 7.71289 8.01579 7.71289C7.8269 7.71289 7.66868 7.77667 7.54112 7.90422C7.41312 8.03222 7.34912 8.19067 7.34912 8.37956V11.0629C7.34912 11.2518 7.41312 11.4073 7.54112 11.5296C7.66868 11.6518 7.8269 11.7129 8.01579 11.7129ZM8.01579 6.37956C8.20468 6.37956 8.36312 6.31556 8.49112 6.18756C8.61868 6.06 8.68245 5.90178 8.68245 5.71289C8.68245 5.524 8.61868 5.36556 8.49112 5.23756C8.36312 5.11 8.20468 5.04622 8.01579 5.04622C7.8269 5.04622 7.66868 5.11 7.54112 5.23756C7.41312 5.36556 7.34912 5.524 7.34912 5.71289C7.34912 5.90178 7.41312 6.06 7.54112 6.18756C7.66868 6.31556 7.8269 6.37956 8.01579 6.37956ZM8.01579 15.0462C7.09357 15.0462 6.2269 14.8711 5.41579 14.5209C4.60468 14.1711 3.89912 13.6962 3.29912 13.0962C2.69912 12.4962 2.22423 11.7907 1.87445 10.9796C1.52423 10.1684 1.34912 9.30178 1.34912 8.37956C1.34912 7.45734 1.52423 6.59067 1.87445 5.77956C2.22423 4.96845 2.69912 4.26289 3.29912 3.66289C3.89912 3.06289 4.60468 2.58778 5.41579 2.23756C6.2269 1.88778 7.09357 1.71289 8.01579 1.71289C8.93801 1.71289 9.80468 1.88778 10.6158 2.23756C11.4269 2.58778 12.1325 3.06289 12.7325 3.66289C13.3325 4.26289 13.8073 4.96845 14.1571 5.77956C14.5073 6.59067 14.6825 7.45734 14.6825 8.37956C14.6825 9.30178 14.5073 10.1684 14.1571 10.9796C13.8073 11.7907 13.3325 12.4962 12.7325 13.0962C12.1325 13.6962 11.4269 14.1711 10.6158 14.5209C9.80468 14.8711 8.93801 15.0462 8.01579 15.0462ZM8.01579 13.7129C9.49357 13.7129 10.752 13.1936 11.7911 12.1549C12.8298 11.1158 13.3491 9.85734 13.3491 8.37956C13.3491 6.90178 12.8298 5.64334 11.7911 4.60422C10.752 3.56556 9.49357 3.04622 8.01579 3.04622C6.53801 3.04622 5.27979 3.56556 4.24112 4.60422C3.20201 5.64334 2.68245 6.90178 2.68245 8.37956C2.68245 9.85734 3.20201 11.1158 4.24112 12.1549C5.27979 13.1936 6.53801 13.7129 8.01579 13.7129Z"
        fill={c}
      />
    </svg>
  )
}
